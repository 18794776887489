<div class="container">
    <h1>Privacy Policy</h1>
    <h2>Personal data protection</h2>
    <p>
        In application of current regulations on data protection, it is reported that the personal data collected through the forms on the website.

        The identity of the DATA CONTROLLER is Maria Angeles Montesdeoca Toribio.
        
        The purpose of the collection and automated processing of personal data is to maintain the business relationship and carry out information, training, advice and other activities of the CONTROLLER.
        
        These data will only be transferred to those entities that are necessary for the sole purpose of fulfilling the aforementioned purpose.
        
        The CONTROLLER adopts the necessary measures to guarantee the security, integrity and confidentiality of the data in accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council, of April 27, 2016, regarding the protection of the natural persons with regard to the processing of personal data and their free circulation.
        
        The user may at any time exercise the rights of access, opposition, rectification and cancellation recognized in the aforementioned Regulation (EU). The exercise of these rights can be done by the user himself by sending an email to angymontesdeocatoribio@gmail.com.
        
        The user declares that all the data provided by him are true and correct, and undertakes to keep them updated, communicating the changes to the CONTROLLER.
    </p>

    <h2>Purpose of the processing of personal data</h2>
    <p>
        We will process your personal data collected through the website for the following purposes:

        1) In the case of contracting the goods and services offered through the website, to maintain the contractual relationship, as well as the management, administration, information, provision and improvement of the service.
        2) Sending requested information through the contact forms.
        3) To send bulletins (newsletters), as well as commercial communications of promotions and / or publicity of the RESPONSIBLE.

        We remind you that you can oppose the sending of commercial communications by any means and at any time, by sending an email to the address indicated above.

        The fields of these records are mandatory, and it is impossible to carry out the stated purposes if these data are not provided.
    </p>

    <h2>For how long is the personal data collected kept?</h2>
    <p>
        The personal data provided will be kept as long as the commercial relationship is maintained or you request its deletion and during the period for which legal responsibilities may arise for the services provided.
    </p>
    
    <h2>Legitimation</h2>
    <p>
        The treatment of your data is carried out with the following legal bases that legitimize it:

        1) The request for information and / or the contracting of the services of the RESPONSIBLE, whose terms and conditions will be made available to you in any case, prior to a possible contracting.
        2) Free, specific, informed and unequivocal consent, while we inform you by making this privacy policy available to you, which, after reading it, if you agree, you can accept by means of a statement or a clear affirmative action, such as marking a box provided for this purpose.

        If you do not provide us with your data or do so in an erroneous or incomplete way, we will not be able to respond to your request, making it impossible to provide you with the requested information or to carry out the contracting of services.
    </p>

    <h2>Recipients</h2>
    <p>
        The data will not be communicated to any third party outside the CONTROLLER, except legal obligation.

        As data managers, we can contract service providers, having committed to compliance with the regulatory provisions, applicable in terms of data protection, at the time of hiring.
    </p>

    <h2>Data collected by users of the services</h2>
    <p>
        In cases where the user includes files with personal data on the shared hosting servers, the CONTROLLER is not responsible for the breach by the user of the RGPD.
    </p>

    <h2>Data retention in accordance with the LSSI</h2>
    <p>
        The RESPONSIBLE informs that, as a data hosting service provider and by virtue of the provisions of Law 34/2002 of July 11, Services of the Information Society and Electronic Commerce (LSSI), it retains for a period A maximum of 12 months the essential information to identify the origin of the hosted data and the moment in which the provision of the service began. The retention of these data does not affect the secrecy of the communications and they may only be used in the framework of a criminal investigation or for the safeguarding of public safety, making themselves available to the judges and / or courts or the Ministry that so requires. .
        The communication of data to the Forces and Bodies of the State will be made by virtue of the provisions of the regulations on personal data protection.
    </p>

    <h2>Protection of hosted information</h2>
    <p>
        El RESPONSABLE realiza copias de seguridad de los contenidos alojados en sus servidores, sin embargo, no se responsabiliza de la pérdida o el borrado accidental de los datos por parte de los usuarios. De igual manera, no garantiza la reposición total de los datos borrados por los usuarios, ya que los citados datos podrían haber sido suprimidos y/o modificados durante el periodo del tiempo transcurrido desde la última copia de seguridad.
    </p>

    <h2>Commercial communications</h2>
    <p>
        In application of the LSSI. The CONTROLLER will not send advertising or promotional communications by email or other equivalent electronic means of communication that have not previously been requested or expressly authorized by the recipients of the same.

        In the case of users with whom there is a prior contractual relationship, the CONTROLLER is authorized to send commercial communications regarding products or services of the CONTROLLER that are similar to those that were initially contracted with the client.

        In any case, the user, after proving her identity, may request that no more commercial information be sent to her through the Customer Service channels.
    </p>
</div>

<footer id="contacto">
    <div class="contenedor footer-content">
        <div class="contact-us">
            <h2 class="brand">Maria de los Angeles Montesdeoca Toribio</h2>
        </div>
    </div>
    <div class="line"></div>
    <p class="copyright">Copyright © 2021 Maria de los Angeles Montesdeoca Toribio</p>
</footer>