<section class="container" id="aboutme">
    <h2 class="subtitle">About me</h2>
    <div class="container-about-me">
        <div class="checklist-about-me">
            <div class="checklist">
                <div class="about-me">
                    <h3 class="n-about-me">My personal side</h3>
                    <p class="about-me-text">
                        I have studied a career focused on the tourism sector and I have worked on it, then I could see that that was not my sector, so I decided to study a master's degree in business to open my possibilities to more companies and not only those of the tourism sector. Later I began to get curious about the programming world and I started to do some research on my own, until one day I said, why not dedicate myself to this that attracts me? Well, then the opportunity to study arose. I took a fullstack web developer course and I decided to turn my career around and not leave that opportunity. After finish the course I could see that I liked it even more than I thought, for what I decided to dedicate myself to it and here I am, learning something new every day about this great and wonderful world and continuing to train in it.                    
                    </p>
                </div>
                <div class="sub-container">
                    <div class="about-me sub-container-skills">
                        <h3 class="n-about-me">Skills</h3>
                        <ul class="skills">
                            <li><i class='bx bx-check'></i><p>Responsible and committed to work</p></li>
                            <li><i class='bx bx-check'></i><p>Positive attitude towards the resolution of difficulties</p></li>
                            <li><i class='bx bx-check'></i><p>Positive and enthusiastic</p></li>
                            <li><i class='bx bx-check'></i><p>I can work well with a wide range of people</p></li>
                        </ul>
                    </div>
                    <div class="about-me sub-container-languages">
                        <h3 class="n-about-me">Languages</h3>
                        <ul class="languages">
                            <li><i class='bx bx-radio-circle' ></i><p>Spanish (Mother tongue)</p></li>
                            <li><i class='bx bx-radio-circle' ></i><p>English B2</p></li>
                            <li><i class='bx bx-radio-circle' ></i><p>German B2</p></li>
                        </ul>
                    </div>
                </div>     
                <div class="about-me">
                    <h3 class="n-about-me">Hobbies</h3>
                    <p class="hobbies">I love nature and doing activities on it, for example, ridding a bike, doing snorkel, swimming in the sea, hiking and some more. Furthermore, I like spending time with my family and friends and go for lunch or dinner to different places to taste new things.</p>
                </div>
                <div class="container-cv">
                    <a href="https://drive.google.com/file/d/1QttW8urbkkEcu6LDFxM_HGHN_6vGiKrG/view?usp=sharing" target="_blank" class="cv bg-purple-500 hover:bg-purple-600">
                        Download CV
                    </a>
                </div> 
            </div>
            <div class="checklist">
                <img src="" alt="">
            </div>
        </div>
    </div>
</section>