<section class="gallery" id="portfolio">
  <div class="container">
    <h2 class="subtitle">Portfolio</h2>
    <div class="container-gallery">
      <img src="../../../assets/img/Hermes.png" alt="" class="img-gallery" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
      <img src="../../../assets/img/LaCuevita.png" alt="" class="img-gallery" data-bs-toggle="modal" data-bs-target="#laCuevitaImpresiones" >
    </div>
    <!-- Modal -->
    <div class="modal" id="staticBackdrop" tabindex="-1">
      <div class="modal-dialog pop-up">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">HermesDuck</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/img/portada.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/registro.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/login.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/admin.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/noticia.png" class="d-block w-100" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <div class="information">
                <div >
                  <a href="http://hermesduck.com/#/" target="_blank" class="web">
                      <i class='bx bx-world'></i>
                      <p>www.hermesduck.com</p>
                  </a>
                </div>
                <div class="technology">
                  <h5>Technology</h5>
                  <ul class="list">
                    <li>
                        HTML
                    </li>
                    <li>
                        CSS
                    </li>
                    <li>
                        TypeScript
                    </li>
                    <li>
                        JavaScript
                    </li>
                    <li>
                        Dockerfile
                    </li>
                  </ul>
                </div> 
              </div>
            </div>
          </div>      
          <div class="description">
            <h5>Description</h5>
            <p>HermesDuck is the final project of the Bootcamp FullStack web Developer carried out by 4 members, which consists of a messaging app, to be able to send newsletters, news or whatever you want to multiple emails or users who have subscribed to your organization, using Angular and Tailwind for frontend CSS and Nodejs and MongoDB for backend, also using Docker and AWS.</p>
          </div>   
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal" id="laCuevitaImpresiones" tabindex="-1">
      <div class="modal-dialog pop-up">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">La Cuevita Impresiones</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div id="carouselExampleIndicators1" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/img/portadacuevita.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/productoscuevita.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/diseñocuevita.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/contactanoscuevita.png" class="d-block w-100" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <div class="information">
                <div >
                  <a href="https://www.lacuevitaimpresiones.com/" target="_blank" class="web">
                      <i class='bx bx-world'></i>
                      <p>www.lacuevitaimpresiones.com</p>
                  </a>
                </div>
                <div class="technology">
                  <h5>Technology</h5>
                  <ul class="list">
                    <li>
                        HTML
                    </li>
                    <li>
                        CSS
                    </li>
                    <li>
                        React
                    </li>
                  </ul>
                </div> 
              </div>
            </div>
          </div>      
          <div class="description">
            <h5>Description</h5>
            <p>La Cuevita Impresiones is web site, where you can check designs and products to order them. This app is developed using AWS.</p>
          </div>   
        </div>
      </div>
    </div>
  </div>
</section>
