<section class="container" id="servicecs">
    <h2 class="subtitle">Services</h2>
    <section class="expert">
        <div class="cont-expert">
            <img src="../../../assets/img/developer.svg" alt="">
            <h3 class="n-expert">Web Developer & Design</h3>
        </div>
        <div class="cont-expert">
            <img src="../../../assets/img/marketing.svg" alt="">
            <h3 class="n-expert">Social Media Marketing</h3>
        </div>
        <div class="cont-expert">
            <img src="../../../assets/img/settings.svg" alt="">
            <h3 class="n-expert">Management</h3>
        </div>
    </section>
</section>
