<!-- <nav class="bg-white shadow" role="navigation">
  <div class="container mx-auto p-4 flex flex-wrap items-center md:flex-no-wrap">
    <div class="ml-auto md:hidden">
      <button class="flex items-center px-3 py-2 border rounded" type="button">
        <svg class="h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
        </svg>
      </button>
    </div> 
    <div class="nav-bar w-full md:w-auto md:flex-grow md:flex md:items-center">
      <ul class="flex flex-col border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:mr-4 lg:mr-8 md:border-0">
        <li>
          <a routerLink="" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="text-purple-600" class="block px-4 py-1 md:p-2 lg:px-4 hover:text-purple-600">Home</a>
        </li>
        <li>
          <a routerLink="about-me" routerLinkActive="text-purple-600" class="block px-4 py-1 md:p-2 lg:px-4 hover:text-purple-600">About me</a>
        </li>
        <li>
          <a routerLink="resume" routerLinkActive="text-purple-600" class="block px-4 py-1 md:p-2 lg:px-4 hover:text-purple-600">Resume</a>
        </li>
        <li>
          <a routerLink="services" routerLinkActive="text-purple-600" class="block px-4 py-1 md:p-2 lg:px-4 hover:text-purple-600">Services</a>
        </li>
        <li>
          <a routerLink="portfolio" routerLinkActive="text-purple-600" class="block px-4 py-1 md:p-2 lg:px-4 hover:text-purple-600">Portfolio</a>
        </li>
        <li>
          <a routerLink="contact" routerLinkActive="text-purple-600" class="block px-4 py-1 md:p-2 lg:px-4 hover:text-purple-600">Contact me</a>
        </li>
      </ul>
    </div>
  </div>
</nav> -->



<nav class="menu navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <!-- <span class="navbar-toggler-icon"></span> -->
      <i class='bx bx-menu-alt-right'></i>
    </button>
    <div class="container-menu collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a href="#home" routerLinkActive="active" class="nav-link" aria-current="page">Home</a>
        </li>
        <li class="nav-item">
          <a href="#aboutme" routerLinkActive="active" class="nav-link">About me</a>
        </li>
        <li class="nav-item">
          <a href="#resume-education-experience" routerLinkActive="active" class="nav-link">Resume</a>
        </li>
        <li class="nav-item">
          <a href="#servicecs" routerLinkActive="active" class="nav-link">Services</a>
        </li>
        <li class="nav-item">
          <a href="#portfolio" routerLinkActive="active" class="nav-link">Portfolio</a>
        </li>
        <li class="nav-item">
          <a href="#contactme" routerLinkActive="active" class="nav-link">Contact me</a>
        </li>
      
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
