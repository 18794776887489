<header class="header" id="home">
    <div class="container head">
        <h1 class="title">Hi! I am Angy</h1>
        <p class="copy">I am FullStack web developer</p>
    </div>
</header>
<app-about-me></app-about-me>
<app-resume></app-resume>
<app-services></app-services>
<app-portfolio></app-portfolio>
<app-contact-me></app-contact-me>