
<div class="bg" id="resume-education-experience">
    <section class="container">
        <div class="container-resume">        
            <div class="images"></div>
            <div class="resume">
                <h2 class="subtitle">Education</h2>
                <ul>
                    <li>
                        <div class="year shadow-md">2021</div>
                        <div class="education">
                            <p>Fullstack developer</p>
                            <small>275 hours course at School of Industrial Organization (EOI) in Las Palmas de Gran Canaria, Spain</small>
                        </div>
                    </li>
                    <li>
                        <div class="year shadow-md">2020</div>
                        <div class="education">
                            <p>Web Analytics and Google Analytics,</p>
                            <p>Web Positioning,</p>
                            <p>Inbound Marketing</p>
                            <small>Course at University Foundation of Las Palmas de Gran Canaria, Spain</small>
                        </div>
                    </li>
                    <li>
                        <div class="year shadow-md">2017 - 2018</div>
                        <div class="education">
                            <p>Master in Business Management and Human Resources</p>
                            <small>Master in Business Management und Human Resources at University of Las Palmas de Gran Canaria, Spain</small>
                        </div>
                    </li>
                    <li>
                        <div class="year shadow-md">2015 - 2016</div>
                        <div class="education">
                            <p>Degree in Tourism</p>
                            <small>Semester abroad at the Stralsund University of Applied Sciences at Hochschule Stralsund, Germany</small>
                        </div>
                    </li>
                    <li>
                        <div class="year shadow-md">2013 - 2017</div>
                        <div class="education">
                            <p>Degree in Tourism</p>
                            <small>Degree in tourism with the designation "Grado" after four years at University of Las Palmas de Gran Canaria, Spain</small>
                        </div>
                    </li>   
                </ul>
            </div>        
        </div>
    </section>
</div>

<section class="container container-experience">
    <div class="experience-title">
        <h2 class="subtitle">Experience</h2>
    </div>
    <div class="container mx-auto w-full h-full">
        <div class="container-experience relative wrap overflow-hidden p-10 h-full">
            <div class="experience-line border-2-2 absolute border-opacity-20 border-gray-700 h-full border" style="left: 50%"></div>
            
            <div class="mb-8 flex justify-between items-center w-full right-timeline">
                
                <div class="experience-sub-container order-1 w-5/12">
                    <h3 class="mb-3 font-bold text-xl">March - June 2021</h3>
                    <p class="parragraph text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">School of Industrial Organization (EOI), Las Palmas de Gran Canaria, Spain</p>
                </div>
                
                <div class="circle z-20 flex items-center order-1 bg-purple-600 shadow-xl w-8 h-8 rounded-full">
                    <h1 class="mx-auto font-semibold text-lg"></h1>
                </div>
                
                <div class="experience-sub-container right order-1 rounded-lg shadow-xl w-5/12 px-6 py-4">
                    <h3 class="mb-3 font-bold text-xl">Final Bootcamp Project</h3>
                    <p class="parragraph text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">Project with Angularjs, using Tailwind CSS</p>
                </div>
    
            </div>
    
            <div class="mb-8 flex justify-between items-center w-full right-timeline">
                
                <div class="experience-sub-container order-1 w-5/12">
                    <h3 class="mb-3 font-bold text-xl">Sept. 2017 - Sept. 2018</h3>
                    <p class="parragraph text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">Servicios Canarios de Traducciones y Congresos, Gran Canaria, Spain</p>
                </div>
                
                <div class="circle z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                    <h1 class="mx-auto font-semibold text-lg text-white"></h1>
                </div>
                
                <div class="experience-sub-container right order-1 rounded-lg shadow-xl w-5/12 px-6 py-4">
                    <h3 class="mb-3 font-bold text-xl">Event hostess</h3>
                    <p class="parragraph text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">Serve customers, accommodate them and provide information</p>
                </div>
    
            </div>
    
            <div class="mb-8 flex justify-between items-center w-full right-timeline">
                
                <div class="experience-sub-container order-1 w-5/12">
                    <h3 class="mb-3 font-bold text-xl">Jun. 2017 - Aug. 2017</h3>
                    <p class="parragraph text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">RK Luz Playa Suites Hotels, Las Palmas de Gran Canaria, Spain</p>
                </div>
                
                <div class="circle z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                    <h1 class="mx-auto font-semibold text-lg text-white"></h1>
                </div>
                
                <div class="experience-sub-container right order-1 rounded-lg shadow-xl w-5/12 px-6 py-4">
                    <h3 class="mb-3 font-bold text-xl">Receptionist</h3>
                    <p class="parragraph text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">Customer service and provide information
                        Check-in and check-out
                        Reservations, receive reservations, assign room,
                        cancel reservations
                        Receive emails and respond
                        Resolution of conflicts and complaints</p>
                </div>
    
            </div>
    
            <div class="mb-8 flex justify-between items-center w-full right-timeline">
                
                <div class="experience-sub-container order-1 w-5/12">
                    <h3 class="mb-3 font-bold text-xl">Feb. 2017 - May 2017</h3>
                    <p class="parragraph text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">Centro de Interpretación de las Dunas de Maspalomas, Maspalomas, Spain</p>
                </div>
                
                <div class="circle z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                    <h1 class="mx-auto font-semibold text-lg text-white"></h1>
                </div>
                
                <div class="experience-sub-container right order-1 rounded-lg shadow-xl w-5/12 px-6 py-4">
                    <h3 class="mb-3 font-bold text-xl">Tourist information</h3>
                    <p class="parragraph text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">Customer service and provide information
                        Organize office information and update it
                        Collect suggestions and complaints from visitors
                        Do Excels in order to know the profiles of the visitors</p>
                </div>
            </div>     
        </div>
    </div>
</section>

<div class="bg">
    <section class="container super-container-skills">  
            <div class="subtitle-skills">
                <h2 class="subtitle">Skills</h2>
            </div>
            
            <div class="container-skills">
                
                <div class="skills">
                    <ul>
                        <li>
                            <p>HTML5</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:90%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>CSS3</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:80%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>Bootstrap</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:70%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>Tailwind CSS</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:60%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="skills">
                    <ul>
                        <li>
                            <p>JavaScript</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:50%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>Angular</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:55%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>React</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:30%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>Node.js</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:40%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="skills">
                    <ul>
                        <li>
                            <p>TypeScript</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:50%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>MongoDB</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:40%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>Docker</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:30%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>Nginx</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:30%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>
                <div class="skills">
                    <ul>
                        <li>
                            <p>Postman API</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:40%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>AWS</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:30%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>GitHub</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:65%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>Trello</p>
                            <div class="relative pt-1">
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                  <div style="width:70%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>
                        </li> 
                    </ul>
                </div>
            </div>
    </section>   
</div>
