import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.css']
})
export class ContactMeComponent implements OnInit {

  name: string = '';
  email: string = '';
  text: string = '';
  successMessage: string = '';
  errorMessage: string = '';
  errorMessageEmail: string = '';
  checkbox: boolean = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  isValidEmail (email: string) {
    return /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
  };

  sendEmail() {
   
    if( this.name.length > 0 && this.email.length > 0 && this.text.length > 0 && this.checkbox ) {
      this.errorMessage = '';
      if(this.isValidEmail(this.email)){
        this.successMessage = '';
        this.errorMessage = '';
        this.errorMessageEmail = '';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.http.post('https://formspree.io/f/xlealaed',
        { name: this.name, email: this.email, message: this.text },
        { 'headers': headers }).subscribe(
          result => {
            return this.successMessage = 'Message sent succesfully'
          },
          () => {
            this.name = '';
            this.email = '';
            this.text = '';
            this.checkbox = false;
          }
        );
      } else {
        this.errorMessageEmail = 'The email is not valid';
      }
    } else {
      this.errorMessage = 'All fields are required';
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
  }
}
