<section class="container" id="contactme">
    <h2 class="subtitle">Get in touch</h2>
    <section class="contact">
        <div class="row">
            <div class="cont-contact private-contact">
                <a href="tel:034692503832" class="icon-text social-media-icon">
                    <i class='bx bxs-phone' ></i>
                    +34 692503832
                </a>
                <a href="mailto:angymontesdeocatoribio@gmail.com" class="icon-text social-media-icon">
                    <i class='bx bx-mail-send' ></i>
                    angymontesdeocatoribio@gmail.com
                </a>   
                <a href="https://www.linkedin.com/in/maria-angeles-montesdeoca-toribio/" target="_blank" class="icon-text social-media-icon">
                    <i class='bx bxl-linkedin' ></i>
                    Mª Ángeles Montesdeoca Toribio
                </a>
                <a href="https://github.com/mariaangelesmt" target="_blank" class="icon-text social-media-icon">
                    <i class='bx bxl-github' ></i>
                    mariaangelesmt
                </a>
                <a href="https://twitter.com/MariaAn72151408" target="_blank" class="icon-text social-media-icon">
                    <i class='bx bxl-twitter' ></i>
                    @MariaAn72151408
                </a>        
            </div>
            <!-- <div class="cont-contact social-media">

            </div>     -->
        </div>
        <div class="row">
            <div>
            <span class="text-sm text-gray-600">Full Name *</span>
            <input [(ngModel)]="name" type="text" name="name" class="w-full text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" type="text" placeholder="Full name"/>
            </div>
            <div class="mt-8">
            <span class="text-sm text-gray-600">Email *</span>
            <input [(ngModel)]="email" type="email" name="email" class="w-full text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" type="text" placeholder="Email"/>
            <small class="error">{{errorMessageEmail}}</small>
            </div>
            <div class="mt-8">
            <span class="text-sm text-gray-600">Message *</span>
            <textarea [(ngModel)]="text" type="text" name="text"
                class="w-full h-32 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" placeholder="Message"></textarea>
            </div>
            <div class="mt-8">
            <div class="privacy-policy-container">
                <input [(ngModel)]="checkbox" name="checkbox" type="checkbox"><span> I have read and accept the </span><a class="privacy-policy" routerLink="privacy-policy" target="_blank">privacy policy</a>
            </div>
            <small class="success">{{successMessage}}</small>
            <small class="error">{{errorMessage}}</small>
            <re-captcha sitekey="6Leg8XQbAAAAAOyoU7633807TLJ8koNi23hFcgro"></re-captcha>
            <!-- <re-captcha (resolved)="resolved($event)" 
              formControlName="recaptchaReactive" 
              siteKey="6Leg8XQbAAAAAOyoU7633807TLJ8koNi23hFcgro">  
            </re-captcha> -->
            <button 
            type="button" class="button uppercase text-sm font-bold tracking-wide bg-purple-500 hover:bg-purple-600 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline" (click)="sendEmail()">
                Send Message
            </button>
            </div>
        </div>
    </section>
</section>

<footer id="contacto">
    <div class="container footer-content">
        <div class="contact-us">
            <h2 class="brand">Maria de los Angeles Montesdeoca Toribio</h2>
        </div>
    </div>
    <div class="line"></div>
    <p class="copyright">Copyright © 2021 Maria de los Angeles Montesdeoca Toribio</p>
</footer>